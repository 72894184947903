.book-now {
    #months, 
    .nivaria-beach & .kora-tab-selector,
    .tigot & .kora-tab-selector {
        display: none !important;
    }

    #days {
        display: flex !important;
    }
}
