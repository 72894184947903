.widget-estudios {
    --text-title-transform: none;

    @media (min-width: $smartphone) {
        &__fold {
           
            .title {
                font-size: rem(90);
            }
        }
    }
}
