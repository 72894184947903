.block-billboard-hero {
    --text-transform: none;
    --text-transform-subtitle: uppercase;

    &__fold {
        h1 {
            font-size: min(var(--serif-large-font-size), 14vw);

            @media (min-aspect-ratio: 4/3) {
                font-size: min(var(--serif-large-font-size), 10vw);
            }
        }
    }
}
