@use "sass:math";

#Header {
    
    
    @media (max-width: $smartphone) {
        --color: var(--primary-brand);
    }
}

.header__toggle-button.button-kora {
    --color1: var(--white);
    --color2: var(--primary-brand);
}
