#Sidemenu {
    --bg: var(--aux-light);
    --color: var(--primary-color);
    
    .nav-item {
        &[aria-current="page"],
        &:hover {
            .__text::before {
                background-color: var(--primary-color);
                color: var(--white);
            }
        }
    }

    .button-kora {
        
            --color1: var(--primary-brand);
            --color2: transparent;
            --bg: transparent;
            --fill: var(--primary-brand);
            --bg-circle: transparent;
            --border-circle: var(--primary-brand);
            --color: var(--primary-brand);
            --color-hover: var(--primary-brand);
            --border: transparent
        
    }
}