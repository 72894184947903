@use "sass:math";

:root {
    --button-font-family: var(--font-sans); 
    --button-font-weight: bold; 
    --button-font-size: #{rem(16)};
    --button-line-height: 1;
    --button-letter-spacing: #{letter-spacing(5)};

    --subtitle-font-family: var(--font-sans-display); 
    --subtitle-font-weight: 400; 
    --subtitle-font-size: #{rem(16)};
    --subtitle-line-height: 1;
    --subtitle-letter-spacing: 0;

    --link-font-family: var(--font-sans-display); 
    --link-font-weight: 400; 
    --link-font-size: #{rem(16)};
    --link-line-height: 1;
    --link-letter-spacing: 0;

    --serif-small-font-family: var(--font-serif); 
    --serif-small-font-weight: 400; 
    --serif-small-font-size: #{rem(42)};
    --serif-small-line-height: 1;
    --serif-small-letter-spacing: 0;

    --serif-large-font-family: var(--font-serif); 
    --serif-large-font-weight: 100; 
    --serif-large-font-size: #{rem(190)};
    --serif-large-line-height: 1;
    --serif-large-letter-spacing: #{letter-spacing(-5)};

    /* CARDS */
    --card-title-font-family: var(--font-sans); 
    --card-title-font-weight: 400; 
    --card-title-font-size: #{rem(42)};
    --card-title-line-height: 1;
    --card-title-letter-spacing: 0;

    --card-title-small-font-family: var(--font-sans); 
    --card-title-small-font-weight: 100; 
    --card-title-small-font-size: #{rem(28)};
    --card-title-small-line-height: 1;
    --card-title-small-letter-spacing: 0;

    --card-body-font-family: var(--font-sans); 
    --card-body-font-weight: 400; 
    --card-body-font-size: #{rem(14)};
    --card-body-line-height: 1.25;
    --card-body-letter-spacing: 0;

    --card-info-font-family: var(--font-sans); 
    --card-info-font-weight: 400; 
    --card-info-font-size: #{rem(11)};
    --card-info-line-height: 1.22;
    --card-info-letter-spacing: 0;

    --card-superindex-font-family: var(--font-sans); 
    --card-superindex-font-weight: 400; 
    --card-superindex-font-size: #{rem(11)};
    --card-superindex-line-height: 1.22;
    --card-superindex-letter-spacing: 0;

    --card-featured-font-family: var(--font-sans); 
    --card-featured-font-weight: 700; 
    --card-featured-font-size: #{rem(12)};
    --card-featured-line-height: 1;
    --card-featured-letter-spacing: 0;

    /* BODY */
    --body-base-font-family: var(--font-sans); 
    --body-base-font-weight: 400; 
    --body-base-font-size: #{rem(16)};
    --body-base-line-height: 1.25;
    --body-base-letter-spacing: 0;

    --body-large-font-family: var(--font-sans); 
    --body-large-font-weight: 400; 
    --body-large-font-size: #{rem(24)};
    --body-large-line-height: 1;
    --body-large-letter-spacing: 0;

    --body-hotel-font-family: var(--font-hotel); 
    --body-hotel-font-weight: 400; 
    --body-hotel-font-size: #{rem(20)};
    --body-hotel-line-height: 1.23;
    --body-hotel-letter-spacing: #{letter-spacing(-1)};

    /* TITLE */
    --title-hotel-h1-font-family: var(--font-hotel); 
    --title-hotel-h1-font-weight: 400; 
    --title-hotel-h1-font-size: #{rem(120)};
    --title-hotel-h1-line-height: .6;
    --title-hotel-h1-letter-spacing: 0;

    --title-hotel-h1b-font-family: var(--font-hotel); 
    --title-hotel-h1b-font-weight: 400; 
    --title-hotel-h1b-font-size: #{rem(120)};
    --title-hotel-h1b-line-height: 1;
    --title-hotel-h1b-letter-spacing: 0;

    // --title-hotel-h2-font-family: var(--font-hotel); 
    // --title-hotel-h2-font-weight: 800; 
    // --title-hotel-h2-font-size: #{rem(60)};
    // --title-hotel-h2-line-height: 1;
    // --title-hotel-h2-letter-spacing: 0;

    --title-hotel-h3-font-family: var(--font-hotel); 
    --title-hotel-h3-font-weight: 400; 
    --title-hotel-h3-font-size: #{rem(48)};
    --title-hotel-h3-line-height: 1;
    --title-hotel-h3-letter-spacing: 0;

    --title-hotel-h3b-font-family: var(--font-hotel); 
    --title-hotel-h3b-font-weight: 400; 
    --title-hotel-h3b-font-size: #{rem(48)};
    --title-hotel-h3b-line-height: 1;
    --title-hotel-h3b-letter-spacing: 0;

    --title-hotel-h4-font-family: var(--font-hotel); 
    --title-hotel-h4-font-weight: 400; 
    --title-hotel-h4-font-size: #{rem(40)};
    --title-hotel-h4-line-height: 1;
    --title-hotel-h4-letter-spacing: 0;

    --title-hotel-h5-font-family: var(--font-hotel); 
    --title-hotel-h5-font-weight: 400; 
    --title-hotel-h5-font-size: #{rem(24)};
    --title-hotel-h5-line-height: 1;
    --title-hotel-h5-letter-spacing: 0;

    --title-hotel-h5b-font-family: var(--font-hotel); 
    --title-hotel-h5b-font-weight: 400; 
    --title-hotel-h5b-font-size: #{rem(24)};
    --title-hotel-h5b-line-height: 1;
    --title-hotel-h5b-letter-spacing: 0;

    --title-sans-h1-font-family: var(--font-sans-display); 
    --title-sans-h1-font-weight: 400; 
    --title-sans-h1-font-size: #{rem(54)};
    --title-sans-h1-line-height: .8;
    --title-sans-h1-letter-spacing: #{letter-spacing(-4)};

    --title-sans-h2-font-family: var(--font-sans-display);
    --title-sans-h2-font-weight: 100; 
    --title-sans-h2-font-size: #{rem(42)};
    --title-sans-h2-line-height: 1;
    --title-sans-h2-letter-spacing: 0;

    --title-sans-offer-font-family: var(--font-sans-display);
    --title-sans-offer-font-weight: 100; 
    --title-sans-offer-font-size: #{rem(62)};
    --title-sans-offer-line-height: 1;
    --title-sans-offer-letter-spacing: 0;

    /* HEADER BAR */
    --header-bar-font-family: var(--font-sans);
    --header-bar-font-weight: 400; 
    --header-bar-font-size: 12px;
    --header-bar-line-height: 1;
    --header-bar-letter-spacing: 0;

    --header-font-family: var(--font-sans);
    --header-font-weight: 400; 
    --header-font-size: #{rem(12)};
    --header-line-height: 1;
    --header-letter-spacing: 0;

    /* BLOG */

    --blog-h3-font-family: var(--font-sans); 
    --blog-h3-font-weight: 700; 
    --blog-h3-font-size: #{rem(42)};
    --blog-h3-line-height: 1;
    --blog-h3-letter-spacing: 0;

    --blog-h4-font-family: var(--font-sans); 
    --blog-h4-font-weight: 700; 
    --blog-h4-font-size: #{rem(24)};
    --blog-h4-line-height: 1;
    --blog-h4-letter-spacing: 0;

    @media (max-width: $smartphone) {
        --button-font-size: #{rem(14)};
        --button-letter-spacing: 0;

        --subtitle-font-size: #{rem(11)};
        --subtitle-line-height: 1;

        --serif-small-font-size: #{rem(36)};
        --serif-small-line-height: .83;

        --serif-large-font-size: #{rem(59)};
        --serif-large-line-height: .8;

        /* CARDS */
        --card-title-font-size: #{rem(20)};
        --card-title-small-font-size: #{rem(20)};

        /* BODY */
        --body-large-font-size: #{rem(20)};

        --body-hotel-font-size: #{rem(16)};
        --body-hotel-line-height: 1.25;

        /* TITLE */
        --title-hotel-h1-font-size: #{rem(32)};
        --title-hotel-h1-line-height: .9;

        --title-hotel-h1b-font-size: #{rem(32)};

        --title-hotel-h3-font-size: #{rem(24)};

        --title-hotel-h3b-font-size: #{rem(24)};

        --title-hotel-h4-font-size: #{rem(28)};

        --title-hotel-h5-font-size: #{rem(22)};

        --title-hotel-h5b-font-size: #{rem(14)};

        --title-sans-h1-font-size: #{rem(40)};
        --title-sans-h1-line-height: .9;
        --title-sans-h1-letter-spacing: 0;

        --title-sans-h2-font-size: #{rem(36)};
        --title-sans-h2-line-height: .85;

        --title-sans-offer-font-size: #{rem(36)};
        --title-sans-offer-line-height: .85;
    }
}

// "title-hotel-h2",
$font-styles: "button", "subtitle", "link", "serif-small", "serif-large",
    "card-title", "card-title-small", "card-body", "card-info", "card-superindex", "card-featured",
    "body-base", "body-large", "body-hotel",
    "title-hotel-h1", "title-hotel-h1b",  "title-hotel-h3", "title-hotel-h3b", "title-hotel-h4", "title-hotel-h5", "title-hotel-h5b",
    "title-sans-h1", "title-sans-h2", "title-sans-offer", "header-bar", "header", "blog-h3", "blog-h4";

@each $style in $font-styles {
    .font-style-#{$style} {
        font-family: var(--#{$style}-font-family);
        font-weight: var(--#{$style}-font-weight);
        font-size: var(--#{$style}-font-size);
        line-height: var(--#{$style}-line-height);
        letter-spacing: var(--#{$style}-letter-spacing);
    }
}  

@mixin font-style($style) {
    @extend .font-style-#{$style};
}


@mixin text-shadow-title() {
    text-shadow: 0px .04em .1em rgba(0,0,0,0.1);
}

@mixin text-shadow-body() {
    text-shadow: 0px .04em .25em rgba(0,0,0,0.41);
}