.block-ubicacion {
    --map-width: 114%;
    --map-offset-left: -7%;

    &__wrapper {
        background-color: var(--aux-medium);
    }

    &__header {
        h2 {
            color: var(--primary-color);
            line-height: .8;
        }

        p {
            color: var(--primary-brand);
            margin-top: 1em;
        }
    }

    .button.--light {
        --color: var(--primary-assertive);
        --bg: var(--primary-color);
        --color-hover: var(--primary-color);
        --bg-hover: var(--primary-light);
    }
}