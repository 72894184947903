.widget-experiencias-full {
    .button-kora {
        &.__prev,
        &.__next {
            --color1: var(--primary-brand);
            --color2: transparent;
            --bg: transparent;
            --fill: var(--primary-brand);
            --bg-circle: transparent;
            --border-circle: var(--primary-brand);
            --color: var(--primary-brand);
            --color-hover: var(--primary-brand);
            --border: transparent
        }
    }
}
