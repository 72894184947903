.block-billboard-home {
    --title-transform: capitalize;
    --title-text-transform: uppercase;

    &__text p:first-of-type {
        @include font-style('body-base');
    }

    //STOP MARQUEE
    &__slider {
        .promise {
            max-width: var(--promise-width);
            
            @media (min-width: $tabletLandscape) {
                > .text {
                    font-size: 3vw;
                }
            }

            @media (min-width: $smartphone) and (max-width: $tabletLandscape) {
                > .text {
                    font-size: 3.3vw;
                }
            }

            @media (max-width: $smartphone) {
                > .text {
                    font-size: 8vw;
                }
            }
        }
    }
}