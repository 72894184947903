.block-experiencias {
    .wrapper {
        color: var(--black);
        background-color: var(--primary-medium);
    }

    h2 {
        color: var(--primary-lighter);
        strong {
            color: var(--primary-lighter);
        }
    }

    .button.--dark {
        --color: var(--primary-color);
        --color-hover: var(--primary-color);
        --bg: var(--aux-light);
        --bg-hover: var(--primary-assertive);
    }
}