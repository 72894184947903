@use "sass:math";

#Footer {
    --bg: var(--primary-medium);
    --bg-fake: var(--primary-light);
    --color-newsletter-title: var(--primary-color);
    --color-rrss: var(--primary-color);
    --color-dark: var(--primary-color);
    --color-wrapper: var(--primary-color);
    --color-legals: var(--primary-color);
    --color-links-small: var(--primary-color);
    --color-logo: var(--primary-brand);
    --color-text: var(--primary-color);
    --color-mega-link: var(--color-dark);

    .button {
        --color: var(--white);
        --color-hover: var(--primary-color);
        --bg: var(--primary-color);
        --bg-hover: var(--primary-assertive);
    }
}
