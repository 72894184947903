@use "sass:math";

:root {
    --zoom: 1;
    --screen-ratio: 1;

    --font-hotel: 'DMSerifDisplay', sans-serif;
    --line-height-hotel: 1.2;
    --letter-spacing-hotel: 0em;

    --font-sans: 'EloquiaText', sans-serif;
    --line-height-sans: 1.2;
    --letter-spacing-sans: -0.06em;

    --font-sans-display: 'EloquiaDisplay', sans-serif;
    --line-height-sans-display: 1.2;
    --letter-spacing-sans-display: -0.06em;

    --font-serif: 'Moneta', serif;
    --line-height-serif: 1.2;
    --letter-spacing-serif: 0em;

    --font-size-base: #{math.div(16px, 16px) * 1rem};
    
    --font-size-xxlarge: #{math.div(42px, 16px) * 1rem};
    --font-size-xlarge: #{math.div(36px, 16px) * 1rem};
    --font-size-large: #{math.div(32px, 16px) * 1rem};
    --font-size-medium: #{math.div(28px, 16px) * 1rem};
    
    --font-size-small: #{math.div(20px, 16px) * 1rem};
    --font-size-xsmall: #{math.div(18px, 16px) * 1rem};
    --font-size-xxsmall: #{math.div(16px, 16px) * 1rem};
    --font-size-xxxsmall: #{math.div(14px, 16px) * 1rem};
    --font-size-xxxxsmall: #{math.div(12px, 16px) * 1rem};
    --font-size-xxxxxsmall: #{math.div(8px, 16px) * 1rem};   

    --min-font-size: 12px;
    --max-font-size: 20px;
    --font-size-ref: 1.1111vw;
    //aqui hay algo para sacar de forma 100% css el modificador vertical, estoy seguro, pero de momento se queda corto.
    //Los dejo para seguir investigando.
    //--mod-font-size: calc(min(1, .8 + min(.4, var(--screen-ratio) * .4)));     
    
    //MIRAI
    --mirai-ui-font: var(--font-sans) !important;


    --font-size: clamp(var(--min-font-size), var(--font-size-ref) * var(--zoom), var(--max-font-size));
    font-size: var(--font-size);

    @media (min-aspect-ratio: 4/2) {
        --font-size-ref: min(16px, 2vh);
    }
    
    @media (max-width: $smartphone) {
        --font-size: 16px;
    }
}

@mixin font-sans($line-height: 0, $weight: 400) {
    font-family: var(--font-sans);
    font-weight: $weight;
    @if $line-height > 0 { line-height: $line-height; }
    @else { line-height: var(--line-height-sans); }
}

@mixin font-sans-display($line-height: 0, $weight: 100) {
    font-family: var(--font-sans-display);
    font-weight: $weight;
    @if $line-height > 0 { line-height: $line-height; }
    @else { line-height: var(--line-height-sans-display);  }
}

@mixin font-serif($line-height: 0, $weight: 400) {
    font-family: var(--font-serif);
    font-weight: $weight;
    @if $line-height > 0 { line-height: $line-height; }
    @else { line-height: var(--line-height-serif); }
}

@mixin font-sans-bold($line-height: 0) { @include font-sans($line-height, 700) };
