.card-distributor {
    --button-color: var(--primary-brand);
}

.card-distributor .button {
    --color: var(--aux-light);
    --bg: var(--primary-brand);
    --color-hover: var(--primary-color);
    --bg-hover: var(--primary-assertive);
}
