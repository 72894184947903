.card-offer-large {
    --bg: var(--primary-medium);
    --color: var(--primary-color);

    .button {
        --color: var(--primary-color);
        --color-hover: var(--primary-assertive);
        --bg: var(--primary-assertive);
        --bg-hover: var(--primary-color);
    }
}